import request from "@/utils/request";

export function del(id) {
  return request({
      url: `/company/${id}`,
      method: 'delete'
  })
}

export function reset(id) {
return request({
  url: `/company/reset/${id}`,
  method: "put",
});
}

export function getCompany() {
  return request({
    url: `/company`,
    method: "get",
  });
}

export function updateCompany(data) {
  return request({
    url: `/company`,
    method: "post",
    data: data,
  });
}

export function getInfo() {
  return request({
    url: `/company/info`,
    method: "get",
  });
}
