<template>
  <div>请您先保存基本信息【我的公司信息】</div>
</template>

<script>
import { getInfo } from "@/api/company/company.js";

export default {
  methods: {
    getInfo() {
      this.loading = true;
      getInfo().then((res) => {
        this.loading = false;
        if (res.id > 0) {
          this.$router.push(`/cms/company/show/${res.id}`);
        }
      });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>

<style></style>
